import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import TokenTable from "~/components/TokenTable/TokenTable";
export const _frontmatter = {
  "menuLabel": "Spacing",
  "sortOrder": 6.4
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Spacing`}</h1>
    <p className="p-large">Consistent spacing help create visual balance and harmony in a UI.</p>
    <p>{`Our spacing values based on a 4px grid because our UI can typically be more dense than other web products. Using a grid with smaller increments gives us more flexibility.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Instead of deciding from over 24 tokens to choose from, we've scoped them down into a smaller collection you can use. See our `}<a parentName="p" {...{
          "href": "/tokens/spacing"
        }}>{`spacing tokens`}</a>{` for our sportsbook and casino products.`}</p>
    </blockquote>
    <TokenTable tokenGroup="Spacing" mdxType="TokenTable" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      